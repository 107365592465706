import React, { useState, useEffect } from 'react';
import { Space, Dropdown, Menu, Col, Row, Badge, Layout, Tooltip, Input, Typography, Button, Select, message, Empty } from 'antd';
import { useHistory, useLocation } from "react-router-dom"
import { MenuUnfoldOutlined, MenuFoldOutlined, DownOutlined, UserAddOutlined, MenuOutlined, LoginOutlined } from '@ant-design/icons';
import axios from 'axios';
import { FaWhatsapp } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io"

import PropTypes from 'prop-types';

import { User, SetUser } from "../../Hooks/Logged";
import CustomAvatar from "../Widgets/Avatar/Avatar";
import { Link, Redirect, } from "react-router-dom";
import Carrito, { SetCarrito } from '../../Hooks/Carrito';
import { SocketContext } from '../../Hooks/Socket';
import Notificaciones from './Notificaciones'
import { IconLightFacebook, IconLightLinkedin, IconLightTwitter, IconLightEnvelope, IconInstagram } from '../Widgets/Iconos';

import '../../Styles/Global/header.scss';


const { SubMenu } = Menu;

const { Header } = Layout;
const { Search } = Input;
const { Title, Paragraph, Text } = Typography;
const { Option, OptGroup } = Select
let myRef = React.createRef();

/**
 * @const Navbar
 * @description Header del sistema
 * @param {*} {  showResponsiveSidebar, showSearch, setFilterSearch, filterSearchVal }
 */
const Navbar = (props) => {

    let { showResponsiveSidebar, showSearch, setFilterSearch, filterSearchVal, admin, collapsed = false, setCollapse } = props


    var ruta = window.location.pathname;

    if (ruta.includes("admin/project-manager/supplychain/") ||
        ruta.includes("admin/project-manager/logistica/") ||
        ruta.includes("admin/project-manager/matriz-costos/")) {
        showSearch = false;
    }
    else {
        showSearch = true;
    }

    const user = React.useContext(User)
    const setUser = React.useContext(SetUser)
    const socket = React.useContext(SocketContext)
    let [notificaciones, setNotificaciones] = useState(0)
    let [conversaciones, setConversaciones] = useState([])

    const [redirect, setRedirect] = useState(false)
    /**
     * @const cerrarSesion
     * @description Cierra la sesion del dashboard
     */
    const cerrarSesion = () => {
        setUser(undefined);
        sessionStorage.clear();
        setRedirect(true);

    };

    let userAvatar;
    let nombre;

    if (typeof user == "object") {
        let { avatar, nombre, apellido } = user;
        nombre = `${user.nombre} ${user.apellido ? user.apellido : ''}`;

        userAvatar = <div><CustomAvatar name={[nombre, apellido]} image={avatar} /><span style={{ marginLeft: '10px' }}>{nombre.length > 25 ? nombre.slice(0, 22) + "..." : nombre}</span></div>
    }


    /**
    * @const NavMenu
    * @description Muestra el Menu dependiendo si hay sesion iniciada o no
    */
    const NavMenu = () => {
        if (user == "object") {
            return [
                <Link to="/admin/dashboard">Dashboard</Link>,
                showResponsiveSidebar && <Link to="/login">Cerrar Sesión</Link>
            ]
        } else {
            return (
                <Link to="/login" className="link-login">
                    Iniciar Sesión
                </Link>
            )
        }
    }




    /**
    * @const renderDesktopNavbar
    * @description Muestra el Menu del tamaño full cover para pantallas grandes
    */
    const renderDesktopNavbar = () => {
        return admin == true ? UserMenu() : NavMenu()

    }

    /**
    * @const renderMobileNavbar
    * @description Muestra el Menu responsivo
    */
    const renderMobileNavbar = () => {
        return showResponsiveSidebar ? '' : NavMenu();
    }

    const add = () => {
        setNotificaciones((prevNoti) => {
            let newNoti = prevNoti + 1;
            return newNoti;
        });
    }


    /**
    * @const UserMenu
    * @description Muestra el Menu del dashboard
    */
    const UserMenu = () => {
        return (
            <Col flex="auto" className="header-content" >
                {redirect ? <Redirect to={'/login'} /> : null}
                <Row>
                    <Col xs={24} md={16} lg={12} xl={17} >

                        <div className="header-search">
                            <Button icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} link onClick={() => setCollapse(!collapsed)} className="no-border" style={{ marginTop: 5, marginRight: 5 }}></Button>
                            {(showSearch) ?
                                <Search
                                    placeholder="Buscar"
                                    onSearch={(e) => { setFilterSearch(e) }}
                                    id="inputFilterSearch"
                                    enterButton
                                    ref={myRef}
                                    className="input-global-search"
                                />
                                :
                                null
                            }
                        </div>
                    </Col>
                    <Col xs={24} md={8} lg={12} xl={7}>
                        <Row type="flex" justify="center" align="middle" className=" header-options">
                            <Col flex={2} className="option-notificacion center">
                                <Notificaciones />
                            </Col>
                            <Col flex={3} className="option-menu center">
                                <Dropdown
                                    arrow={true}
                                    overlay={
                                        <Menu>
                                            <Menu.Item key="0" onClick={cerrarSesion}>
                                                Cerrar Sesión
                                            </Menu.Item>
                                        </Menu>
                                    } trigger={['click']}>
                                    <div >
                                        <a>
                                            {userAvatar}
                                        </a>
                                        <Link onClick={e => e.preventDefault()}
                                            style={{ marginLeft: '2rem' }}>
                                            {nombre} <DownOutlined />
                                        </Link>
                                    </div>
                                </Dropdown>
                            </Col>
                        </Row>
                    </Col>
                </Row>


            </Col>
        )
    }

    return (
        <Header className="header" >
            <Row type="flex" className="row-header-content" >
                <Col className={"header-logo center" + (collapsed ? " collapse" : "")}  >
                    <Link to={(user) ? ((user.tipo === 1) ? "/admin/dashboard" : "/customer/misPedidos") : "/"} className="logo">
                        <img src={'/images/logo.png'} alt="logo" />
                    </Link>
                </Col>
                {renderDesktopNavbar()}
            </Row>
        </Header>
    );

}


/**
 * @const Navbar
 * @description Header del sistema
 * @param {*} {  showResponsiveSidebar, showSearch, setFilterSearch, filterSearchVal }
 */
const PublicNavbar = ({ proveedor }) => {

    const history = useHistory()
    const location = useLocation()

    const user = React.useContext(User)
    const setUser = React.useContext(SetUser)

    const setCarrito = React.useContext(SetCarrito)
    const carrito = React.useContext(Carrito)
    const [redirect, setRedirect] = useState(false)
    const [proveedores_catalogos, setProveedoresCatalogos] = useState([])
    const [search, setSearch] = useState(null)

    const [cotizadorViewer, setCotizadorViewer] = useState(false)
    const [catalogosViewer, setCatalogosViewer] = useState(true)


    useEffect(() => {
        getProveedoresCatalogos()
    }, [])


    useEffect(() => {
        if (location?.search) {
            let query = new URLSearchParams(location?.search)
            let _search = query.get('search')
            if (_search) setSearch(_search)
        }
    }, [location?.search])

    let userAvatar;
    let nombre;

    if (typeof user == "object") {
        let { avatar, nombre, apellido } = user;
        nombre = `${user.nombre} ${user.apellido ? user.apellido : ''}`;
        userAvatar = <div><CustomAvatar name={[nombre, apellido]} image={avatar} /><span style={{ marginLeft: '10px' }}>{nombre}</span></div>
    }



    /**
     * @method getProveedoresCatalogos
     * @description Obtenemos proveedores disponibles y sus catalogos
     *
     */
    const getProveedoresCatalogos = () => {
        axios.get('/proveedores/catalogos')
            .then(({ data }) => {
                setProveedoresCatalogos(data.data)
            })
            .catch(e => {
                message.error("No fue posible obtener los catálogos.")
            })
    }



    // 1) Estilo de la barra de navegación
    // 2) Consulta de proveedores
    /**
     * @type {*}
     * @const catalogos 
     * @description Lista de Proveedores y Catalogos
     *  */
    const catalogos = () => (
        <Menu style={{ width: 256 }} mode="vertical">
            {/* {} */}
            {(proveedores_catalogos?.length > 0) ? proveedores_catalogos.map(({ _id, nombre, catalogos }) => <SubMenu key={_id} title={nombre}>
                {catalogos.map((catalogo) => <Menu.Item key={catalogo._id}>
                    <Link to={`/catalogo/${nombre}/${catalogo.url}`}>{catalogo.nombre}</Link>
                </Menu.Item>)}
            </SubMenu>) : <Empty description="No hay Catalogos disponibles actualmente" />}
        </Menu>
    )


    return (
        <Header className="header-public">
            <Col span={24} className="information-bar">
                <Row className="header-section-container" align={"middle"}>
                    <Col xs={24} sm={12}>
                        <div className='information-bar-flex'>
                            <Button size="small" type="text" target={"_blank"} href='https://www.facebook.com/Nexus-Nova-Trading-Company-105945065544723' icon={<IconLightFacebook width={18} height={18} />} />
                            <Button size="small" type="text" target={"_blank"} href='https://www.instagram.com/nexusnova_' icon={<IoLogoInstagram style={{ color: '#ffffff', width: 24, height: 24 }} />} />
                            <Button size="small" type="text" target={"_blank"} href='https://www.linkedin.com/in/nexus-nova-trading-company-43bb0a234/' icon={<IconLightLinkedin width={18} height={18} />} />
                            <Button size="small" type="text" target={"_blank"} href='https://twitter.com/NexusNovaCo' icon={<IconLightTwitter width={18} height={15.36} />} />
                            <Tooltip placement="bottom" title={'soporte@nexusnova.com.mx'}>
                                <Button size="small" type="text" href='mailto:soporte@nexusnova.com.mx' icon={<IconLightEnvelope width={18} height={15.36} />} />
                            </Tooltip>
                            {
                                // ? Whatsapp Link 
                            }<Button size="small" type="text" target={"_blank"} href='https://wa.me/+524492041003' >
                                +52 449 204 1003
                            </Button>
                        </div>
                    </Col>
                    <Col xs={24} sm={12}>
                        <div className='information-bar-flex mail'>
                            <IconLightEnvelope width={18} height={15.36} />
                            <Button size="small" type="text" target={"_blank"} href='mailto:soporte@nexusnova.com.mx' >
                                soporte@nexusnova.com.mx
                            </Button>
                        </div>
                    </Col>
                </Row>

            </Col>
            <Col span={24} className="control-bar">
                <Row className="header-section-container" style={{ margin: "2em auto" }}>
                    <Row gutter={[25, 25]} className='control-bar-content' justify={"center"} align={"middle"} style={{ margin: "0 10px" }}>
                        <Col xs={16} sm={6} md={5} lg={4}>
                            <Link to={(user) ? ((user.tipo === 1) ? "/admin/dashboard" : "/customer/misPedidos") : "/"}>

                                <img src="/images/logo.svg" className="width-100" />
                            </Link>

                        </Col>
                        <Col xs={24} sm={12} md={14} lg={15} className="gutter-row">
                            <Input
                                className="search-bar"
                                size="large"
                                placeholder="¿Que estás buscando?"
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                            />
                        </Col>
                        <Col xs={24} sm={6} md={5} lg={5} className='control-bar-actions'>
                            <Button onClick={() => {
                                // if(!search) {
                                //     message.info("Ingrese un dato a buscar")
                                //     return
                                // }
                                let s = {
                                    pathname: '/productos',
                                    // search: `?search=${search}`
                                }
                                if (search) {
                                    s['search'] = `?search=${search}`
                                }
                                history.push(s)
                            }} size="large" className='actions-search' style={{ backgroundColor: "#E76D5B", color: "white", minHeight: "50px" }}>Buscar</Button>
                            <Tooltip title="Cotizador">
                                <Link to="/marketplace">
                                    {
                                        carrito.length > 0 ? (
                                            <Badge title='Productos' count={carrito.length}>
                                                <img src="/images/cart.svg" />
                                            </Badge>
                                        ) : (
                                            <img src="/images/cart.svg" />
                                        )
                                    }
                                </Link>
                            </Tooltip>

                            <Tooltip title="Registrarse">
                                <Link to="/register" style={{ display: 'flex', alignItems: 'center' }}>
                                    <UserAddOutlined style={{ color: '#4F4F4F', fontSize: 20 }} />
                                </Link>
                            </Tooltip>
                            <Tooltip title="Iniciar Sesión">
                                <Link to="/login" style={{ display: 'flex', alignItems: 'center' }}>
                                    <LoginOutlined style={{ color: '#4F4F4F', fontSize: 20 }} />
                                </Link>
                            </Tooltip>
                        </Col>

                    </Row>
                </Row>
            </Col>
            <Col span={24} className="menu-bar">
                <Row className="header-section-container" justify={"space-between"}>
                    <Col xs={12} lg={7} xl={5} className='menu-bar-left'>
                        {/* <Dropdown className='menu-bar-dropdown' 
                        
                        overlay={catalogos()}
                        trigger={['click']}>
                            <Space><MenuOutlined /> Catalogos</Space>
                        </Dropdown> */}
                        <Link to="/productos" className='menu-bar-dropdown'>
                            <Space><MenuOutlined /> Catalogos</Space>
                        </Link>
                    </Col>
                    <Col xs={12} lg={10} xl={14} className='menu-bar-center'>
                        <Space size={48} className='menu-bar-links'>
                            <Link to={"/"}>Inicio</Link>
                            <Link to={"/#about-us"}>¿Quienes Somos?</Link>
                            <Link to={"/productos"}>Productos</Link>
                        </Space>
                    </Col>
                    <Col xs={12} lg={7} xl={5} className='menu-bar-right'>
                        <div className='menu-bar-contact'>
                            <div className='contact-icon-wrapper'><FaWhatsapp className='contact-icon' /></div>
                            <div className='contact-content'>
                                <Text className='contact-text'>¡Comunicate con nosotros!</Text>
                                <a href="https://wa.me/+524492041003" className='contact-number'>+52 449 204 1003</a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Header>
    );

}


Navbar.propTypes = {
    title: PropTypes.string,
    back: PropTypes.bool,
    onBack: PropTypes.func
};

Navbar.defaultProps = {
    back: false,
    title: 'Nexus',
    showSearch: true
};

export { PublicNavbar }
export default Navbar;
