import React, { Component } from "react";
import {
    message,
    Checkbox,
    Table,
    Statistic,
    Layout,
    Typography,
    Drawer,
    Divider,
    Space,
    Button,
    Form,
    Spin,
    Row,
    Col,
    Input,
    Select,
    DatePicker,
    Modal,
    Tooltip,
    InputNumber,
    List
} from "antd";

import "../../../../Styles/Modules/Finanzas/DrawerTimbrado.scss";
import { PrinterOutlined, CloseOutlined, } from "@ant-design/icons";


import { GoFileCode } from "react-icons/go";
import { IconSAT } from "../../../Widgets/Iconos";

import Decimal from "decimal.js";

import { c_TipoDeComprobante, c_RegimenFiscal, c_UsoCFDI, c_Exportacion, c_FormaPago, c_TipoDeRelación } from "../../../SATCatalogos/index";
import { FaRegHandScissors } from "react-icons/fa";

const { Content } = Layout;
const { Text, Title, Paragraph } = Typography
const { Option } = Select


const axios = require('axios')
const moment = require('moment')


/**
 * @export
 * @class EgresosList
 * @extends {Component}
 * @description Vista de Project Manager
 */
class TimbradoOrden extends Component {


    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            disabled: false,
            newData: [],
            orden: {
                impuestos: 0,
                subtotal: 0,
            },
            total_original: 0,
            factura: [],
            facturas_nota_credito: [],
            moneda: "MXN",
            razon_social_id: null,


            razones_sociales: {
                data: [],
                page: 1,
                limit: 20,

                pages: 0,
                total: 0,

                search: null,
            },
            transacciones: {
                data: [],
                page: 1,
                limit: 20,

                pages: 0,
                total: 0,

                search: null,
            },
            facturas: {
                data: [],
                page: 1,
                limit: 100,

                pages: 0,
                total: 0,

                search: null,
            }
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrden()
        this.getTipoCambio()
        this.getRazonesSociales()

        if (this.props.transaccion_id) {
            this.getTransaccion()
            this.getTransacciones()
            this.getFacturas()
        }

        if(this.props.nota_credito_drawer){
            this.getFacturas()
        }

    }

    /**
     * @memberof DrawerTimbrado
     * @method getOrden
     * @description Obtenemos las ordenes_detalles de una orden para el timbrado, para despues parsear ls datos y cargarlos 
     */
    getOrden = ({ orden_id = this.props.orden_id } = this.props) => {
        this.props.setLoading(true)
        axios.get('/syncfy/ordenes_detalles', { 
            params: { 
                orden_id, 
                cliente_id: true 
            } 
        }).then(({ data }) => {
            console.log("data", data);
            this.parseOrden(data)
        }).catch(({ response }) => {
            message.error("Error al obtener los detalles de la orden")
        }).finally(() => this.props.setLoading(false))
    }

    /**
     * @memberof DrawerTimbrado
     * @method getOrden
     * @description Obtenemos las ordenes_detalles de una orden para el timbrado, para despues parsear los datos y cargarlos 
     */
    parseOrden = (orden = this.state.orden) => {
        let facturacion = {
            Serie: (orden.tipo_orden === 1) ? "SPT" : "VMI",
            Folio: (orden.folio_number).toString().padStart(7, "0"),
            TipoDeComprobante: "I",
            Receptor: {
                Nombre: orden?.cliente_id.razon_social,
                Rfc: orden?.cliente_id.rfc,
                DomicilioFiscalReceptor: orden?.cliente_id.codigo_postal,
                RegimenFiscalReceptor: orden?.cliente_id.regimen_fiscal,
            },
        }

        //Si no se pasa una transaccion ni es para notas de credito, se rellena la informacion de la factura con los detalles de la orden
        if (!this.props.transaccion_id && !this.props.nota_credito_drawer){
            this.setState({total_original: orden.total})
            facturacion.Total = orden.total;
            facturacion.SubTotal = orden.subtotal;
            facturacion.Impuestos = {
                TotalImpuestosTrasladados: orden.impuestos,
                TotalImpuestosRetenidos: 0
            }
            facturacion.Conceptos = orden.ordenes_detalles.map(e => ({
                Unidad: e.producto_id?.unidad_nombre ?? "Pieza",
                ClaveUnidad: e.producto_id?.unidad ?? "H87",
                ValorUnitario: e?.info_matriz?.precio_venta_unitario ?? 0,
                Cantidad: e.cantidad,
                _id: e._id,
                ClaveProdServ: e.producto_id.clave_producto ? e.producto_id.clave_producto : "50181906",
                NoIdentificacion: e.producto_id.sku,
                Importe: e?.info_matriz?.total_venta,
                Descripcion: e.producto_id.descripcion,
                Descuento: "0",
                ObjetoImp: e.producto_id.impuestos ? "01" : "02",
                NoImpuesto: e.producto_id.impuestos || false,
                ...(e.producto_id.impuestos ? {} : {
                    Impuestos: {
                        Traslados: [{
                            Base: e?.info_matriz?.total_venta,
                            Impuesto: '002',
                            TipoFactor: 'Tasa',
                            TasaOCuota: "0.160000",
                            Importe: Decimal(e.info_matriz.total_venta).mul(Decimal(0.16)).toDecimalPlaces(2).toNumber().toString(),
                        }],
                    }
                }),
            }))
        }

        if(this.props.nota_credito_drawer){
            facturacion.Serie = "NC"
            facturacion.TipoDeComprobante = "E"
            facturacion.Receptor = {
                Nombre: orden?.cliente_id.razon_social,
                Rfc: orden?.cliente_id.rfc,
                DomicilioFiscalReceptor: orden?.cliente_id.codigo_postal,
                RegimenFiscalReceptor: orden?.cliente_id.regimen_fiscal,
                UsoCFDI: "G02" 
            }
            facturacion.Total = 0
            facturacion.SubTotal = 0
            facturacion.Impuestos = {
                TotalImpuestosTrasladados: 0,
                TotalImpuestosRetenidos: 0
            }
        }

        this.formTimbrado.current.setFieldsValue(facturacion)
        this.setState({orden})
    }

    /**
     * @memberof DrawerTimbrado
     * @method getTipoCambio
     * @description Obtenemos el tipo de cambio y cargarlo en la factura
     */
    getTipoCambio = () => {
        axios.get("/tipo-cambio/get")
        .then(({ data }) => {
            this.formTimbrado.current.setFieldsValue({ TipoCambio: data?.data[0]?.valor ?? 18 })
        })
    }

    /**
     *
     * @memberof DrawerTimbrado
     * @method getRazonesSociales
     * @description Obtiene el listado de los Razones Sociales
     */
    getRazonesSociales = ({
        page = this.state.razones_sociales.page,
        limit = this.state.razones_sociales.limit,
        search = this.state.razones_sociales.search
    } = this.state.razones_sociales) => {
        this.setState({ razones_sociales: { ...this.state.razones_sociales, page, limit, search } })
        axios.get('/razones-sociales', {
            params: {
                page, limit, search
            }
        })
        .then(({ data }) => {
            this.setState({
                razones_sociales: {
                    ...this.state.razones_sociales,
                    data: data.data.itemsList,
                    total: data.data.paginator?.itemCount,
                    pages: data.data.paginator?.pageCount,

                }
            })
        })
        .catch(res => {
            console.log("error", res);
            message.error("Error al obtener las razones sociales")
        });
    }


    /**
     * @memberof DrawerTimbrado
     * @method getTransaccion
     * @description Obtenemos la transaccion, carga los datos en la factura 
     */
    getTransaccion = (transaccion_id = this.props.transaccion_id) => {
        this.props.setLoading(true)
        axios.post('/transacciones/get', {
            id: transaccion_id
        })
        .then(({ data }) => {
            let { monto, monto_dolar, _id } = data.data
            let Conceptos = [
                {
                    "ClaveProdServ": "84111506",
                    "Cantidad": "1",
                    "ClaveUnidad": "ACT",
                    "Descripcion": "Pago",
                    "ValorUnitario": monto,
                    "Importe": monto,
                    "ObjetoImp": "01"
                }
            ]
            this.formTimbrado.current.setFieldsValue({
                Conceptos,
                SubTotal: monto,
                Total: monto,
                TotalDolar: monto_dolar,
                Impuestos: {
                    TotalImpuestosRetenidos: 0,
                    TotalImpuestosTrasladados: 0
                },
                transaccion_id: {
                    label: this.renderTransaccion(data.data),
                    value: _id,
                    key: _id,
                },
                TipoDeComprobante: "P",
                Serie: "PE",
                Folio: data.folio

            })
            this.setState({total_original: monto})
        })
        .catch(({ response }) => {
            console.log("response X", response)
            message.error("Error al obtener la transacción")
        })
        .finally(() => this.props.setLoading(false))
    }


    getTransacciones = ({

        page = this.state.transacciones.page,
        limit = this.state.transacciones.limit,
        search = this.state.transacciones.search,

    } = this.state.transacciones) => {
        this.props.setLoading(true)
        this.setState({ transacciones: { ...this.state.transacciones, page, limit, search } })
        axios.post('/transacciones/list', {
            id: this.props.orden_id,
            ...this.props.cliente_id ? { cliente_id: this.props.cliente_id } : {}
        })
            .then(({ data }) => {
                this.setState({
                    transacciones: {
                        ...this.state.transacciones,
                        data: data.data?.itemsList,
                        pages: data.data?.pageCount,
                        total: data.data?.itemCount,
                    }
                })
            })
            .catch(({ response }) => {

            })
            .finally(() => this.props.setLoading(false))
    }

     /**
     * @memberof DrawerTimbrado
     * @method getFacturas
     * @description Obtenemos la lista de facturas relacionas a la orden y notas de credito en caso necesario 
     */
    getFacturas = ({
        page = this.state.facturas.page,
        limit = this.state.facturas.limit,
        search = this.state.facturas.search,
    } = this.state.facturas) => {
        this.props.setLoading(true)
        this.setState({ facturas: { ...this.state.facturas, page, limit, search } })
        axios.get('/facturas/list', {
            params: {
                page,
                limit,
                search,
                orden_id: this.props.orden_id,
                ...this.props.cliente_id ? { cliente_id: this.props.cliente_id } : {},
                ...this.props.vmi ? { vmi: this.props.vmi } : {},
            }
        })
            .then(({ data }) => {
                this.setState({
                    facturas: {
                        ...this.state.facturas,
                        data: data.data?.itemsList,
                        pages: data.data?.pageCount,
                        total: data.data?.itemCount,
                    }
                })
            })
            .catch(({ response }) => {

            })
            .finally(() => this.props.setLoading(false))
    }


    updateValores = (changedValues, allValues) => {

        let { Conceptos } = allValues

        if (!Boolean(this.props.transaccion_id) && Boolean(changedValues.Conceptos)) {
            let update = {}

            let index = changedValues.Conceptos.length - 1
            let completo = Conceptos[index]

            let importe = Decimal(completo.Cantidad).mul(Decimal(completo.ValorUnitario)).toDecimalPlaces(2).toNumber()

            let impuesto = Decimal(importe).mul(Decimal(0.16)).toDecimalPlaces(2).toNumber()

            Conceptos[index].Importe = importe
            Conceptos[index].Impuestos.Traslados[0].Base = importe
            Conceptos[index].Impuestos.Traslados[0].Impuesto = completo.NoImpuesto ? "001" : "002"
            Conceptos[index].Impuestos.Traslados[0].Importe = completo.NoImpuesto ? 0 : impuesto
            Conceptos[index].ObjetoImp = completo.NoImpuesto ? "01" : "02"

            let subtotal = Conceptos.reduce((a, b) => Decimal(a).add(b.Importe), 0)
            let impuestos = Conceptos.reduce((a, b) => b.NoImpuesto === false ? Decimal(a).add(b.Impuestos.Traslados[0].Importe) : Decimal(a).add(0), 0)
            let total = Decimal(subtotal).add(Decimal(impuestos)).toDecimalPlaces(2).toNumber()
            update = {
                Total: total,
                SubTotal: Decimal(subtotal).toDecimalPlaces(2).toNumber(),
                Impuestos: {
                    TotalImpuestosTrasladados: Decimal(impuestos).toDecimalPlaces(2).toNumber(),
                },
                Conceptos: Conceptos
            }

            if (changedValues.Impuestos || changedValues.SubTotal)
                update.Total = Decimal(allValues.SubTotal).add(Decimal(allValues.Impuestos.TotalImpuestosTrasladados)).add(Decimal(allValues.Impuestos.TotalImpuestosRetenidos)).toDecimalPlaces(2).toNumber()

            return this.formTimbrado.current.setFieldsValue(update)
        }

        if (
            Boolean(this.props.transaccion_id) && (
                changedValues.Total || changedValues.SubTotal || (Array.isArray(changedValues.Conceptos) && (changedValues.Conceptos[0]?.Importe || changedValues.Conceptos[0]?.ValorUnitario)) ||
                changedValues.Moneda ||
                changedValues.TipoCambio ||
                changedValues.TotalDolar)
        ) {

            // Si es la cantidad en PESOS
            let monto = changedValues.Total || changedValues.SubTotal || (Array.isArray(changedValues.Conceptos) && (changedValues.Conceptos[0]?.Importe || changedValues.Conceptos[0]?.ValorUnitario)) || allValues.Total

            //Si es la cantidad en dolares
            let TipoCambio = allValues.TipoCambio

            let monto_dolar = changedValues.TotalDolar

            if (changedValues.TotalDolar)
                monto = Decimal(monto_dolar).mul(TipoCambio).toDecimalPlaces(2).toNumber()
            else
                monto_dolar = Decimal(monto).div(TipoCambio).toDecimalPlaces(2).toNumber()

            let update = {}
            Conceptos[0].Importe = monto
            Conceptos[0].ValorUnitario = monto


            update = {
                Total: monto,
                SubTotal: monto,
                TotalDolar: monto_dolar,
                Conceptos
            }
            this.formTimbrado.current.setFieldsValue(update)
        }
    }

    /**
     * @memberof DrawerTimbrado
     * @method formatValues
     * @description Formatea los datos de la factura para enviarlos al back para realizar el timbrado
     */
    formatValues = (values) => {

        let { enviar_receptor, enviar_responsable, observaciones, transaccion_id, Total } = values, temp = values
        let { total_original } = this.state;

        if(Total === 0){
            message.error("El valor total no puede ser igual a 0")
            return;
        }
        // Calcular el límite inferior y superior permitido
        var limiteInferior = Decimal(total_original).mul(0.5).toDecimalPlaces(2).toNumber();
        var limiteSuperior = Decimal(total_original).mul(1.5).toDecimalPlaces(2).toNumber(); 

        // Verificas si el nuevo total está dentro del rango permitido
        if (Total < limiteInferior || Total > limiteSuperior) {
            message.error("El valor total no es valido, sobrepasa los limites")
            return;
        }

        let { factura } = this.state

        transaccion_id = transaccion_id?.value || transaccion_id
        let FormaDePagoP = values.FormaPago

        delete values["enviar_receptor"]
        delete values["enviar_responsable"]
        delete values["transaccion_id"]
        delete values["observaciones"]

        let BaseImpuestos = values.SubTotal

        values.Conceptos.map(e => {
            if (e.ObjetoImp === "01") {
                delete e.Impuestos
                BaseImpuestos = Decimal(BaseImpuestos).sub(e.Importe).toDecimalPlaces(2).toNumber()
            }
        })



        let TipoCambio = (values.Moneda == "MXN") ? 1 : values.TipoCambio
        values = {
            ...values,
            Version: "4.0",
        }

        if (this.props.transaccion_id) {

            delete values["TipoCambio"]
            values = {
                ...values,
                'Conceptos': [
                    {
                        "ClaveProdServ": "84111506",
                        "Cantidad": "1",
                        "ClaveUnidad": "ACT",
                        "Descripcion": "Pago",
                        "ValorUnitario": 0,
                        "Importe": 0,
                        "ObjetoImp": "01"
                    }
                ],
                'Moneda': "XXX",
                'Complemento': {
                    "Any": [
                        {
                            "Pago20:Pagos": {
                                "Pago": [
                                    {
                                        "FechaPago": moment(values.Fecha).format("YYYY-MM-DDThh:mm:ss"),
                                        "FormaDePagoP": FormaDePagoP,
                                        "MonedaP": values.Moneda,
                                        "Monto": (values.Moneda == "MXN") ? values.Total : values.TotalDolar,
                                        "NumOperacion": "12",
                                        "TipoCambioP": TipoCambio,
                                        "DoctoRelacionado": factura.map( f => (
                                            {
                                                "IdDocumento": f.uuid,
                                                "Serie": f.serie,
                                                "Folio": f.folio,
                                                "MonedaDR": values.Moneda,
                                                "MetodoDePagoDR": f.metodo_pago,
                                                "NumParcialidad": "1",
                                                "ImpSaldoAnt": (values.Moneda == "MXN") ? f.total : Decimal(f.total).div(TipoCambio).toDecimalPlaces(2).toNumber(),
                                                "ImpPagado": (values.Moneda == "MXN") ? f.total : Decimal(f.total).div(TipoCambio).toDecimalPlaces(2).toNumber(),
                                                "ImpSaldoInsoluto": "0.00",
                                                "ObjetoImpDR": "01",
                                                "EquivalenciaDR": "1"
                                            }
                                        ))
                                    }
                                ],
                                "Totales": {
                                    "MontoTotalPagos": values.Total,
                                }
                            }
                        }
                    ]
                },
                'SubTotal': 0,
                'Total': 0,
                'Fecha': values.Fecha.format("YYYY-MM-DDThh:mm:ss")
            }
            delete values["TipoCambio"]
            delete values["TotalDolar"]
            delete values["FormaPago"]

        } else if (this.props.nota_credito_drawer){

            let traslados = []

            for(let concepto of values.Conceptos){
                traslados.push(...concepto.Impuestos.Traslados)
            }


            values = {
                ...values,
                Descuento: 0,
                TipoCambio: TipoCambio,
                Impuestos: {
                    TotalImpuestosTrasladados: values.Impuestos.TotalImpuestosTrasladados,
                    Traslados: traslados
                }
            }



        } else {

            if (values["FormaPago"] == "" || values["FormaPago"] == null) {
                delete values["FormaPago"]
            }

            values.TipoCambio = TipoCambio
            values.Descuento = "0"
            values.Impuestos.Traslados = [
                {
                    Base: BaseImpuestos,
                    Impuesto: '002',
                    TipoFactor: 'Tasa',
                    TasaOCuota: '0.160000',
                    Importe: values.Impuestos.TotalImpuestosTrasladados
                }
            ]
        }

        this.saveFactura({
            invoice_data: values,
            enviar_responsable,
            enviar_receptor,
            observaciones,
            orden_id: this.props.orden_id,
            transaccion_id,
            facturas_id: factura.map(e => e._id),

            moneda: temp.Moneda,
            tipo_cambio: TipoCambio,//: temp.TipoCambio,
            forma_pago: this.state.factura?.forma_pago,//: temp.FormaPago,
            metodo_pago: this.state.metodo_pago?.metodo_pago,//: temp.MetodoPago,

            total: Total,

        })
    }



    /**
     * @memberof DrawerTimbrado
     * @method saveFactura
     * @description Timbra la factura con Syncfy y la guarda
     */
    saveFactura = (values) => {
        this.props.setLoading(true)
        return axios.post('/syncfy/timbrado', {
            ...values,
            nota_credito_create: this.props.nota_credito_drawer ?? undefined,
        }).then(response => {
                message.success('¡Factura Timbrada exitosamente!')
                this.getOrden()
                this.props.onSuccess()
            })
            .catch(error => {
                console.log(error)
                if (Array.isArray(error.response?.data?.data)) {
                    this.setState({ errores: error.response.data.data })
                    Modal.error({
                        title: 'Error al timbrar la factura',
                        content: <ul>
                            {error.response.data.data.map(e => <li>{e}</li>)}
                        </ul>
                    })
                } else {
                    message.error('Error al timbrar la facturas')
                }
            })
            .finally(() => this.props.setLoading(false))

    }

    /**
     * @memberof DrawerTimbrado
     * @method onSelectFacturasNotaCreadito
     * @description En caso de que se vaya a crear una nota de credito y se seleccione alguna factura, calcula la suma de los totales de la factura
     * y carga la info en el formulario     
     * */
    onSelectFacturasNotaCreadito = ({ value, content }) => {
        let facturas = [...this.state.facturas_nota_credito]
        facturas.push({...content})
        this.setState({ facturas_nota_credito: facturas })

        console.log("facturas", facturas);
        let monto = 0, imp_trasladados = 0

        for(let factura of facturas){
            monto = Decimal(monto).add(factura.total).toDecimalPlaces(2).toNumber()
        }


        let conceptos_facturas = [
            {
                "ClaveProdServ": "84111506",
                "Cantidad": "1",
                "ClaveUnidad": "ACT",
                "Descripcion": "Pago",
                "ValorUnitario": monto,
                "Importe": monto,
                "ObjetoImp": "01"
            }
        ]

        if(content.conceptos?.length > 0){
            conceptos_facturas = []
            monto = 0
            for(let factura of facturas){

                if(factura.conceptos.length > 0){
                    for(let cons of factura.conceptos){
                        conceptos_facturas.push({...cons, NoImpuesto: false})
                        monto = Decimal(monto).add(parseFloat(cons.Importe)).toDecimalPlaces(2).toNumber()
                        imp_trasladados = Decimal(imp_trasladados).add(parseFloat(cons.Impuestos.Traslados[0].Importe)).toDecimalPlaces(2).toNumber()
                    }
                }
            }

        }
        this.formTimbrado.current.setFieldsValue({
            Conceptos: conceptos_facturas,
            SubTotal: monto,
            Total: Decimal(monto).add(imp_trasladados).toDecimalPlaces(2).toNumber(),
            TotalDolar: Decimal(monto).div(this.state.TipoCambio ?? 18).toDecimalPlaces(2).toNumber(),
            Impuestos: {
                TotalImpuestosTrasladados: imp_trasladados,
                TotalImpuestosRetenidos: 0,
            },
            //TipoDeComprobante: "P",
            Serie: "NC",
            //Folio: this.state.orden.folio

        })

        this.setState({total_original: monto})

    }

    formTimbrado = React.createRef()


    renderTransaccion = (transaccion) => <Space className="width-100" style={{ justifyContent: "space-between" }}>
        <Text strong>{transaccion.concepto} <small> <i>({moment(transaccion.createdAt).format("YY/MM/DD")})</i></small> <small><i>{(transaccion.cuenta_id?.nombre)}</i></small> </Text>
        <Text>{(transaccion.monto ?? 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Text>
    </Space>

    renderFactura = ({ concepto, serie, folio, uuid, monto, createdAt }) => <Space direction="vertical" className="width-100">
        <Space className="width-100" style={{ justifyContent: "space-between" }}>
            <Text strong>{concepto} <small>{serie}-{folio}</small> <i><small> ({moment(createdAt).format("YY/MM/DD")})</small> </i></Text>
            <Text>{(monto ?? 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Text>
        </Space>
        <Text italic>{uuid}</Text>
    </Space>

    /**
     * @return {*} 
     * @memberof EgresosList
     */
    render() {
        const { disabled } = this.state
        return (
            <Form
                layout="vertical"
                ref={this.formTimbrado}
                initialValues={{
                    TipoDeComprobante: 'I',
                    Moneda: 'MXN',
                    Fecha: moment(),
                    Exportacion: '01',
                    enviar_receptor: true,
                    enviar_responsable: true,

                }}
                onFinish={this.formatValues}
                onValuesChange={this.updateValores}
                onFinishFailed={({ }) => {
                    Modal.error({
                        title: "Faltan campos para proceder con el timbrado",
                        content: "Revise los campos que hacen falta completar para timbrar correctamente"
                    })
                }}
            >
                <Spin spinning={this.state.loading}>
                    <Row justify="center" align="center">
                        <img src="/images/sat.svg" alt="SAT - Servicio de Administración Tributaria" width={"100%"} style={{ marginTop: "2em", marginBottom: "4em", maxWidth: 350 }} />
                    </Row>
                    <Row>
                        <Divider orientation="left" style={{ margin: "12px 0 24px 0 " }} />
                        <Title level={5} className="mb-1">Información del Documento Fiscal</Title>
                    </Row>
                    <Row gutter={[16, 16]} >
                        <Col xs={20} md={12}>

                            <Form.Item
                                name="Serie"
                                label="Serie"
                                rules={[{
                                    required: true,
                                    message: "Ingrese la serie de la factura."
                                }]}
                            >
                                <Select disabled={this.props.disabled}>
                                    <Option value={process.env.REACT_APP_SPOT_SERIE}><strong>{process.env.REACT_APP_SPOT_SERIE}</strong> - SPOT</Option>
                                    <Option value={process.env.REACT_APP_VMI_FACTURA_SERIE}><strong>{process.env.REACT_APP_VMI_FACTURA_SERIE}</strong> - Factura de Almacen (VMI)</Option>
                                    <Option value={process.env.REACT_APP_COMPROBANTE_SERIE}><strong>{process.env.REACT_APP_COMPROBANTE_SERIE}</strong> - Comprobante de Pago</Option>
                                    <Option value={process.env.REACT_APP_NOTA_CREDITO}><strong>{process.env.REACT_APP_NOTA_CREDITO}</strong> - Nota de Crédito</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name="Folio"
                                label="Folio"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el folio"
                                }]}
                            >
                                <Input disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name="Fecha"
                                label="Fecha"
                                rules={[{
                                    required: true,
                                    message: "Ingrese la Fecha"
                                }]}
                            >
                                <DatePicker showTime className="width-100" disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name="TipoDeComprobante"
                                label="Tipo De Comprobante"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el Tipo de Comprobante"
                                }]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="filter"
                                    disabled={this.props.disabled}
                                >
                                    {c_TipoDeComprobante.map(({ tipo_comprobante, descripción }) => <Option filter={`${tipo_comprobante} ${descripción}`} value={tipo_comprobante}><strong>{tipo_comprobante}</strong> - {descripción}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name="FormaPago"
                                label="Forma de Pago"
                            >
                                <Select disabled={this.props.disabled}>
                                    {[
                                        <Option value={null}>SIN FORMA DE PAGO</Option>,
                                        ...c_FormaPago.map(({ forma_pago, descripcion }) => <Option value={forma_pago}><strong>{forma_pago}</strong> - {descripcion}</Option>)
                                    ]}
                                </Select>
                            </Form.Item>
                        </Col>

                        {(!this.props.transaccion_id) && <Col xs={20} md={12}>
                            <Form.Item
                                name="MetodoPago"
                                label="Método de Pago"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el Método de Pago"
                                }]}
                            >
                                <Select disabled={this.props.disabled}>
                                    <Option value={'PUE'}><strong>PUE</strong> - Pago en Una sola Exhibición</Option>
                                    <Option value={'PPD'}><strong>PPD</strong> - Pago en Parcialidades o Diferido</Option>
                                </Select>
                            </Form.Item>
                        </Col>}
                        <Col xs={20} md={12}>
                            <Form.Item
                                name="Exportacion"
                                label="Exportacion"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el Tipo de Exportación"
                                }]}
                            >
                                <Select disabled={this.props.disabled}>
                                    {c_Exportacion.map(({ exportacion, descripción }) => <Option filter={`${exportacion} ${descripción}`} value={exportacion}><strong>{exportacion}</strong> - {descripción}</Option>)}
                                </Select>
                            </Form.Item>

                        </Col>

                        <Col xs={20} md={12}>
                            <Form.Item
                                name="LugarExpedicion"
                                label="Lugar Expedicion (C.P.)"
                                rules={[{
                                    required: true,
                                    message: "Ingrese un código postal"
                                }]}
                            >
                                <Input disabled={this.props.disabled} className="width-100" />
                            </Form.Item>

                        </Col>
                    </Row>
                    {this.props.transaccion_id && <>
                        <Row>
                            <Divider orientation="left" style={{ margin: "12px 0 24px 0 " }} />
                            <Title level={5}>Información de Relación a Factura</Title>
                        </Row>
                        <Row className="mb-1" gutter={[16, 16]}>
                            <Col xs={20} md={8}>
                                <Form.Item
                                    name="transaccion_id"
                                    label="Transacción a generar el Comprobante"
                                    rules={[{
                                        required: true,
                                        message: "Seleccione una transacción para generar el comprobante"
                                    }]}
                                >
                                    <Select
                                        placeholder="Seleccione la Transacción o el Pago a Timbrar"
                                        filterOption={false}
                                        showArrow
                                        className="width-100"
                                        onSearch={(search) => this.getTransacciones({ search, page: 1 })}
                                        showSearch
                                        allowClear
                                        labelInValue
                                    >
                                        {this.state.transacciones.data.map(transaccion => <Option value={transaccion._id}>
                                            {this.renderTransaccion(transaccion)}
                                        </Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={20} md={8}>
                                <Form.Item
                                    name={["CfdiRelacionados ", "UUID"]}
                                    label="Factura a relacionar"
                                    rules={[{
                                        required: true,
                                        message: "Seleccione la factura a relacionar"
                                    }]}
                                >
                                    <Select
                                        placeholder="Seleccione la factura o el pago a timbrar"
                                        filterOption={false}
                                        showArrow
                                        className="width-100"
                                        onSearch={(search) => this.getFacturas({ search, page: 1 })}
                                        showSearch
                                        allowClear
                                        mode="multiple"
                                        onSelect={(value, { content }) => { 
                                            let facturas = [...this.state.factura]
                                            facturas.push({...content})
                                            this.setState({ factura: facturas })}
                                        }
                                        onDeselect={(value_des)=>{
                                            let facturas = [...this.state.factura]

                                            let index = facturas.findIndex(e => e.uuid === value_des )
                                            if(index != -1){
                                                facturas.splice(index,1)
                                            } 

                                            this.setState({ factura: facturas })
                                        }}
                                    >
                                        {this.state.facturas.data.map(factura => <Option value={factura.uuid} content={factura}>
                                            {this.renderFactura(factura)}
                                        </Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={20} md={8}>
                                <Form.Item
                                    name={["CfdiRelacionados ", "TipoRelacion"]}
                                    label="Facturación Relacional"
                                    rules={[{
                                        required: true,
                                        message: "Ingrese la Razón Social desde donde se efectuará la factura"
                                    }]}
                                >
                                    <Select
                                        disabled={this.props.disabled}
                                        placeholder="Seleccione el tipo de Relación"
                                        filterOption={false}
                                    >
                                        {c_TipoDeRelación.map(({ tipo_relacion, descripción }) => <Option filter={`${tipo_relacion} ${descripción}`} value={tipo_relacion}><strong>{tipo_relacion}</strong> - <Text style={{ wordWrap: 'break-word' }}>{descripción}</Text></Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>}

                    {this.props.nota_credito_drawer ? <>
                        <Row>
                            <Divider orientation="left" style={{ margin: "12px 0 24px 0 " }} />
                            <Title level={5}>Información de Relación a Facturas</Title>
                        </Row>
                        <Row className="mb-1" gutter={[16, 16]}>
                            <Col xs={20} md={8}>
                                <Form.Item
                                    name={["CfdiRelacionados ", "UUID"]}
                                    label="Factura a relacionar"
                                    rules={[{
                                        required: true,
                                        message: "Seleccione la factura a relacionar"
                                    }]}
                                >
                                    <Select
                                        placeholder="Seleccione la factura o el pago a timbrar"
                                        filterOption={false}
                                        showArrow
                                        className="width-100"
                                        onSearch={(search) => this.getFacturas({ search, page: 1 })}
                                        showSearch
                                        allowClear
                                        mode="multiple"
                                        onSelect={(value, { content }) => { 
                                            this.onSelectFacturasNotaCreadito({ value, content })
                                        }}
                                        onDeselect={(value_des)=>{
                                            let facturas = [...this.state.facturas_nota_credito]
                                            let index = facturas.findIndex(e => e.uuid === value_des )
                                            if(index != -1){
                                                facturas.splice(index,1)
                                            } 
                                            this.setState({ facturas_nota_credito: facturas })
                                        }}
                                    >
                                        {this.state.facturas.data.map(factura => <Option value={factura.uuid} content={factura}>
                                            {this.renderFactura(factura)}
                                        </Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={20} md={8}>
                                <Form.Item
                                    name={["CfdiRelacionados ", "TipoRelacion"]}
                                    label="Facturación Relacional"
                                    rules={[{
                                        required: true,
                                        message: "Ingrese la Razón Social desde donde se efectuará la factura"
                                    }]}
                                >
                                    <Select
                                        disabled={this.props.disabled}
                                        placeholder="Seleccione el tipo de Relación"
                                        filterOption={false}
                                    >
                                        {c_TipoDeRelación.map(({ tipo_relacion, descripción }) => <Option filter={`${tipo_relacion} ${descripción}`} value={tipo_relacion}><strong>{tipo_relacion}</strong> - <Text style={{ wordWrap: 'break-word' }}>{descripción}</Text></Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </> : null}

                    <Row>
                        <Divider orientation="left" style={{ margin: "12px 0 24px 0 " }} />
                        <Title level={5} className="mb-1">Información del Emisor</Title>
                    </Row>
                    <Row className="mb-1">
                        <Col span={24}>
                            <Form.Item noStyle
                                name="enviar_responsable"
                                valuePropName="checked">
                                <Checkbox >Enviar al Responsable una copia de la factura</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="center" gutter={[16, 16]} >
                        <Col xs={20} md={12}>
                            <Form.Item
                                name="razon_social_id"
                                label="Razón Social"
                                rules={[{
                                    required: true,
                                    message: "Ingrese la Razón Social desde donde se efectuará la factura"
                                }]}
                            >
                                <Select
                                    disabled={this.props.disabled}
                                    placeholder="Seleccione la razon social"
                                    onSearch={(search) => this.getRazonesSociales({ page: 1, search })}
                                    filterOption={false}
                                    onSelect={(value, { razon_social }) => {
                                        this.formTimbrado.current.setFieldsValue({
                                            Emisor: {
                                                Nombre: razon_social.razon_social,
                                                Rfc: razon_social.rfc,
                                                RegimenFiscal: razon_social.regimen_fiscal,
                                            },
                                        })
                                        this.setState({ razon_social_id: razon_social._id })
                                    }}
                                >
                                    {this.state.razones_sociales.data.map(rs => <Option value={rs._id} razon_social={rs}  >{rs.razon_social}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Emisor", "Nombre"]}
                                label="Nombre del Emisor"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el nombre del Emisor (como se registró en el SAT)"
                                }]}
                            >
                                <Input disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Emisor", "Rfc"]}
                                label="Registro Federal de Contribuyentes (RFC)"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el RFC del Emisor"
                                }]}
                            >
                                <Input disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Emisor", "RegimenFiscal"]}
                                // name="RegimenFiscal"
                                label="Régimen Fiscal"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el Régimen Fiscal"
                                }]}
                            >
                                <Select
                                    disabled={this.props.disabled}
                                    showSearch
                                    optionFilterProp="filter"
                                >
                                    {c_RegimenFiscal.map(({ regimen_fiscal, descripción }) => <Option filter={`${regimen_fiscal} ${descripción}`} value={regimen_fiscal}><strong>{regimen_fiscal}</strong> - {descripción}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Emisor", "DomicilioFiscalEmisor"]}
                                label="Domicilio Fiscal Emisor (Código Postal)"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el domicilio del emisor."
                                }]}
                            >
                                <Input disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Divider orientation="left" style={{ margin: "12px 0 24px 0 " }} />
                        <Title level={5} className="mb-1">Información del Receptor</Title>
                    </Row>
                    <Row className="mb-1">
                        <Col span={24}>
                            <Form.Item noStyle
                                name="enviar_receptor"
                                valuePropName="checked">
                                <Checkbox >Enviar al Receptor una copia de la factura</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={20} md={24}>
                            <Form.Item
                                name={["Receptor", "Nombre"]}
                                label="Nombre / Razón Social del Emisor"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el Nombre / Razón Social del Receptor"
                                }]}
                            >
                                <Input disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Receptor", "Rfc"]}
                                label="Registro Federal de Contribuyentes (RFC)"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el RFC del cliente / receptor."
                                }]}
                            >
                                <Input disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Receptor", "DomicilioFiscalReceptor"]}
                                label="Domicilio Fiscal Receptor (Código Postal)"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el domicilio del cliente."
                                }]}
                            >
                                <Input disabled={this.props.disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Receptor", "UsoCFDI"]}
                                label="Uso del CFDI"
                                rules={[{
                                    required: true,
                                    message: "Seleccione una opción."
                                }]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="filter"
                                    disabled={this.props.disabled}
                                >
                                    {c_UsoCFDI.map(({ uso_cfdi, descripción }) => <Option filter={`${uso_cfdi} ${descripción}`} value={uso_cfdi}><strong>{uso_cfdi}</strong> - {descripción}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={12}>
                            <Form.Item
                                name={["Receptor", "RegimenFiscalReceptor"]}
                                label="Régimen Físcal Receptor"
                                rules={[{
                                    required: true,
                                    message: "Seleccione un régimen fiscal"
                                }]}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="filter"
                                    disabled={this.props.disabled}
                                >
                                    {c_RegimenFiscal.map(({ regimen_fiscal, descripción }) => <Option filter={`${regimen_fiscal} ${descripción}`} value={regimen_fiscal}><strong>{regimen_fiscal}</strong> - {descripción}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Divider orientation="left" style={{ margin: "12px 0 24px 0 " }} />
                        <Title level={5}>Información de Pagos y Productos</Title>
                    </Row>

                    <Row className="mb-3" gutter={[16, 16]}>
                        <Col xs={20} md={6}>
                            <Form.Item
                                name="Moneda"
                                label="Moneda"
                            >
                                <Select disabled={this.props.disabled} onSelect={moneda => this.setState({ moneda })}>
                                    <Option value={'MXN'}>MXN</Option>
                                    <Option value={'USD'}>USD</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={6}>
                            <Form.Item
                                name="TipoCambio"
                                label="Tipo de Cambio"
                            >
                                <InputNumber
                                    style={{ width: "100%" }}
                                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={(value) => value && value.replace(/\$\s?|(,*)/g, '')}
                                    disabled={this.state.moneda == "MXN"}
                                />
                            </Form.Item>

                        </Col>
                        <Col xs={24} >
                            <Form.Item
                                label="Observaciones"
                                name="observaciones"
                                // formatter="(\d)(?=(\d\d\d)+(?!\d))"
                                rules={[{
                                    max: 1000,
                                    message: "Máximo de caracteres alcanzado (1000)"
                                }]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>
                        {(this.props.transaccion_id) && <Paragraph strong >Si está generando un recibo de pago en dolares, es necesario revisar que tanto la cantidad en dolares como la cantidad de pesos concuerden con la orden y la factura</Paragraph>}
                        <Col xs={24}>
                            <Form.List
                                prefixCls="Conceptos"
                                name="Conceptos"
                            >

                                {(fields, { add, remove }, { errors }) => (
                                    <Table
                                        className="table-products"
                                        size="small"
                                        columns={(() => {
                                            let pipeline = []

                                            if (!this.props.transaccion_id)
                                                pipeline.push({
                                                    width: 40,
                                                    fixed: "left",
                                                    title: "No Identificacion (SKU)",
                                                    dataIndex: "NoIdentificacion",
                                                    key: "NoIdentificacion",
                                                    width: 60,
                                                    render: (item, { key, name, }) => <Form.Item name={[name, "NoIdentificacion"]} noStyle>
                                                        <Input disabled bordered={false} />
                                                    </Form.Item>
                                                })


                                            pipeline.push(
                                                {

                                                    title: "ClaveProdServ",
                                                    dataIndex: "ClaveProdServ",
                                                    key: "ClaveProdServ",
                                                    width: this.props.transaccion_id ? 70 : 40,
                                                    render: (item, { name }, index) => <Form.Item name={[name, "ClaveProdServ"]} noStyle

                                                        rules={!this.props.transaccion_id ? [
                                                            {
                                                                required: true,
                                                                message: "Ingresé el codigo de producto o servicio adecuado"
                                                            }
                                                        ] : []}
                                                    >
                                                        <Input disabled={!this.props.transaccion_id} bordered={false} />
                                                    </Form.Item>,
                                                },
                                                {
                                                    title: "Descripcion",
                                                    dataIndex: "Descripcion",
                                                    key: "Descripcion",
                                                    width: 100,
                                                    render: (item, { name }, index) => <span className="text-center">
                                                        {(!this.props.transaccion_id) && <>
                                                            <Form.Item name={[name, "_id"]} noStyle>
                                                                <input type="hidden" />
                                                            </Form.Item>
                                                            <Form.Item name={[name, "NoIdentificacion"]} noStyle>
                                                                <input type="hidden" />
                                                            </Form.Item>
                                                            <Form.Item name={[name, "Descripcion"]} noStyle>
                                                                <input type="hidden" />
                                                            </Form.Item>
                                                            <Form.Item name={[name, "Descuento"]} noStyle>
                                                                <input type="hidden" />
                                                            </Form.Item>
                                                            <Form.Item name={[name, "ObjetoImp"]} noStyle>
                                                                <input type="hidden" />
                                                            </Form.Item>
                                                            <Form.Item name={[name, "Impuestos", "Traslados", 0, "Base"]} noStyle>
                                                                <input type="hidden" />
                                                            </Form.Item>
                                                            {/*<Form.Item name={[name, "Impuestos", "Traslados", 0, "Importe"]} noStyle>
                                                                <input type="hidden" />
                                                            </Form.Item>*/}
                                                            <Form.Item name={[name, "Impuestos", "Traslados", 0, "Impuesto"]} noStyle>
                                                                <input type="hidden" />
                                                            </Form.Item>
                                                            <Form.Item name={[name, "Impuestos", "Traslados", 0, "TasaOCuota"]} noStyle>
                                                                <input type="hidden" />
                                                            </Form.Item>
                                                            <Form.Item name={[name, "Impuestos", "Traslados", 0, "TipoFactor"]} noStyle>
                                                                <input type="hidden" />
                                                            </Form.Item>
                                                        </>}
                                                        <Form.Item name={[name, "Descripcion"]} noStyle>
                                                            <Input.TextArea autoSize disabled bordered={false} />
                                                        </Form.Item>
                                                    </span>,
                                                },
                                                {
                                                    title: "Cantidad",
                                                    dataIndex: "Cantidad",
                                                    key: "Cantidad",
                                                    width: 50,
                                                    render: (text, { name }, index) => <Form.Item name={[name, "Cantidad"]}>
                                                        <InputNumber
                                                            controls={false}
                                                            disabled={this.props.disabled || !!this.props.transaccion_id}
                                                            // onChange={e => this.updateValores({ ...record, cantidad: e }, index)}
                                                            bordered={false}
                                                            className="text-center" />
                                                    </Form.Item>
                                                    ,
                                                },
                                                {
                                                    title: "Unidad",
                                                    dataIndex: "Unidad",
                                                    key: "Unidad",
                                                    width: 50,
                                                    render: (text, { name }, index) => <Form.Item
                                                        name={[name, 'ClaveUnidad']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                whitespace: true,
                                                                message: "Falta el Material",
                                                            },
                                                        ]}
                                                    >
                                                        <Select disabled={this.props.disabled} bordered={false}>
                                                            <Option value={'H87'}>Pieza</Option>
                                                            <Option value={'EA'}>Elemento</Option>
                                                            <Option value={'KGM'}>Kilogramo</Option>
                                                            <Option value={'MTR'}>Metro</Option>
                                                            <Option value={'KT'}>Kit</Option>
                                                            <Option value={'SET'}>Conjunto</Option>
                                                            <Option value={'LTR'}>Litro</Option>
                                                            <Option value={'XBX'}>Caja</Option>
                                                            <Option value={'xun'}>Unidad</Option>
                                                            <Option value={'GRM'}>Gramo</Option>
                                                        </Select>
                                                    </Form.Item>
                                                },
                                                {
                                                    title: "Valor Unitario",
                                                    dataIndex: "ValorUnitario",
                                                    key: "ValorUnitario",
                                                    width: 30,

                                                    render: (text, { name }, index) => <Form.Item name={[name, "ValorUnitario"]} noStyle>
                                                        <InputNumber
                                                            style={{ textAlign: "end", width: "100%" }}
                                                            controls={false}
                                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            disabled={this.props.disabled}
                                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                            // defaultValue={record.info_matriz.precio_venta_unitario}
                                                            // onChange={e => this.updateValores({ ...record, info_matriz: { ...record.info_matriz, precio_venta_unitario: e } }, index)}
                                                            bordered={false}
                                                        />
                                                    </Form.Item>,
                                                },
                                                {
                                                    title: "Libre de Impuestos",
                                                    dataIndex: "impuestos",
                                                    key: "impuestos",
                                                    width: 30,

                                                    render: (text, { name }, index) =>
                                                        <Form.Item 
                                                            name={[name, "NoImpuesto"]} 
                                                            noStyle 
                                                            valuePropName="checked"
                                                        >
                                                            <Checkbox/>
                                                        </Form.Item>

                                                    ,
                                                },
                                            )
                                                
                                            if(this.props.nota_credito_drawer){
                                                pipeline.push({
                                                    title: "Impuestos Tras.",
                                                    dataIndex: "impuestos_tras",
                                                    key: "impuestos_tras",
                                                    width: 30,

                                                    render: (text, { name }, index) =>
                                                        <Form.Item name={[name, "Impuestos", "Traslados", 0, "Importe"]} noStyle>
                                                            <Input disabled={true} bordered={false}/>
                                                        </Form.Item>

                                                    ,
                                                },)
                                            }

                                            pipeline.push({
                                                    title: "Importe ",
                                                    dataIndex: "Importe ",
                                                    key: "Importe   ",

                                                    width: 50,
                                                    fixed: 'right',

                                                    render: (text, { name }, index) => <Form.Item name={[name, "Importe"]} noStyle>
                                                        <InputNumber
                                                            style={{ textAlign: "end", width: "100%" }}
                                                            controls={false}
                                                            disabled={this.props.disabled}
                                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                            bordered={false}
                                                        />
                                                    </Form.Item>
                                                })

                                            return pipeline
                                        })()}
                                        dataSource={fields}
                                        pagination={false}
                                        scroll={{ x: 1000, }}
                                        bordered={true}
                                    />
                                )}
                            </Form.List>
                            <List
                                className="list-totals"
                                size="small"
                                bordered={false}
                            >
                                <List.Item>
                                    <Space style={{ justifyContent: "space-between", width: "100%" }}>
                                        <Text strong >SubTotal</Text>
                                        <Form.Item name="SubTotal" noStyle>
                                            <InputNumber
                                                size="large"
                                                controls={false}
                                                style={{ minWidth: 150 }}
                                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                disabled={this.props.disabled}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                bordered={false} />
                                        </Form.Item>
                                    </Space>
                                </List.Item>
                                {(!this.props.transaccion_id) && <>
                                    <List.Item>
                                        <Space style={{ justifyContent: "space-between", width: "100%" }}>
                                            <Text strong >Total Impuestos Trasladados (IVA) (16%)</Text>
                                            <Form.Item name={["Impuestos", "TotalImpuestosTrasladados"]} noStyle>
                                                <InputNumber
                                                    size="large"
                                                    controls={false}
                                                    style={{ minWidth: 150 }}
                                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    disabled={this.props.disabled}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                    bordered={false} />
                                            </Form.Item>
                                        </Space>
                                    </List.Item>
                                    {/* <List.Item>
                                        <Space style={{ justifyContent: "space-between", width: "100%" }}>
                                            <Text strong >Total Impuestos Retenidos</Text>
                                            <Form.Item name={["Impuestos", "TotalImpuestosRetenidos"]} noStyle>
                                                <InputNumber
                                                    size="large"
                                                    controls={false}
                                                    style={{ minWidth: 150 }}
                                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    disabled={this.props.disabled}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                    bordered={false} />
                                            </Form.Item>
                                        </Space>
                                    </List.Item> */}
                                </>}
                                <List.Item>
                                    <Space style={{ justifyContent: "space-between", width: "100%" }}>
                                        <Text strong >Total</Text>
                                        <Form.Item name="Total" noStyle>
                                            <InputNumber
                                                size="large"
                                                controls={false}
                                                style={{ minWidth: 150 }}
                                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                disabled={this.props.disabled}
                                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                bordered={false} />
                                        </Form.Item>
                                    </Space>
                                </List.Item>
                                {(this.props.transaccion_id) && <>
                                    <List.Item style={{ opacity: this.state.moneda == "USD" ? undefined : 0.5 }}>
                                        <Space style={{ justifyContent: "space-between", width: "100%" }}>
                                            <Text strong >Total (USD)</Text>
                                            <Form.Item name={["TotalDolar"]} noStyle>
                                                <InputNumber
                                                    disabled={this.state.moneda != "USD"}
                                                    size="large"
                                                    controls={false}
                                                    style={{ minWidth: 150 }}
                                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                    bordered={false} />
                                            </Form.Item>
                                        </Space>
                                    </List.Item>
                                </>}
                            </List>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}

/**
 *
 *
 * @export
 * @param {*} props
 * @return {*} 
 */
export default function (props) {

    let { onClose, visible } = props
    let [loading, setLoading] = React.useState(false)
    let [disabled, setDisabled] = React.useState(false)

    const timbradoOrden = React.createRef()



    /**
     *
     *
     * @export
     * @param {*} props
     * @return {*} 
     * 
     * @description Cerramos y limpiamos el DRAWER de facturación.
     */
    let onCloseEvent = () => {
        onClose();
        setLoading(false)
        setDisabled(false)
    }

    return <Drawer
        title={<span className="title">Generación del Comprobante Fiscal Digital</span>}
        placement="right"
        onClose={onCloseEvent}
        visible={visible}
        width={1250}
        style={{ maxWidth: "100%" }}
        destroyOnClose
        extra={
            <Space>
                <Button loading={loading} disabled={disabled} icon={<IconSAT size={14} color="currentColor" style={{ position: "relative", top: 2, right: 3 }} />} type="primary" onClick={() => timbradoOrden.current.formTimbrado.current.submit()}>Timbrar</Button>
                <Button loading={loading} danger icon={<CloseOutlined />} onClick={onClose}>Cancelar</Button>
            </Space>
        }
    >
        <Spin spinning={loading}>
            <TimbradoOrden
                loading={loading}
                disabled={disabled}
                setLoading={setLoading}
                setDisabled={setDisabled}
                ref={timbradoOrden}
                {...props}
                onClose={onCloseEvent} />
        </Spin>
    </Drawer>
}